import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  TextField,
  createFilterOptions
} from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { fetchAllAssignee, fetchLoanFilterValue } from "../../api";
import { usersMail } from "../../utility/StagesV2";
import DateRangePicker from "../DateRangePicker";
import { LoadingSkeleton } from "./LoadingSkeleton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LoanApplicationFilter = forwardRef(
  ({ open, handleClose, onApply, options, name, filterApplied }, ref) => {
    const columns = {
      field: "",
      value: [],
      options,
    };

    const overAllOptions = [
      "pending",
      "approved",
      "rejected",
      "disbursed",
      "closed",
    ];

    const initialState = [{ ...columns }];

    const [filterDataValue, setFilterDataValue] = useState([]);
    const [filterData, setFilterData] = useState(initialState);
    const [selectData, setSelectData] = useState([]);
    const [reduceData, setReduceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const getFilterValue = async () => {
      try {
        setLoading(true);
        let { data } = await fetchLoanFilterValue();
        setFilterDataValue(data);
        // console.log("data",data)
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    const getUsers = async () => {
      try{
        const { data } = await fetchAllAssignee(JSON.stringify(["SALES"]));
        setUsers(data?.users);
      }catch(error){
        toast.error(error?.response?.data?.message || error?.message);
      }
    }

    useEffect(() => {
      if (filterApplied) {
        getUsers();
        getFilterValue();
        // localStorage.getItem(`selectData${name}`) &&
        //   setSelectData(JSON.parse(localStorage.getItem(`selectData${name}`)));
        if (localStorage.getItem(`filterData${name}`)) {
          // handleReduceFilterValue(true);
          setFilterData([
            ...JSON.parse(localStorage.getItem(`filterData${name}`)),
          ]);
          handleReduceFilterValue(true);
        } else {
          setFilterData([...initialState]);
        }
        // localStorage.getItem(`reduceData${name}`) &&
        //   setReduceData(JSON.parse(localStorage.getItem(`reduceData${name}`)));
      } else {
        setFilterDataValue([]);
        setFilterData(initialState);
        setSelectData([]);
        setReduceData([]);
      }
    }, [filterApplied]);

    const isPrevCompleted = () => {
      let finalObj = filterData[filterData?.length - 1] ?? {};
      for (let value of Object.values(finalObj)) {
        if (["", null, undefined]?.includes(value)) {
          return false;
        }
      }
      return true;
    };

    const addMore = () => {
      if (!isPrevCompleted()) {
        return;
      }

      setFilterData([
        ...filterData,
        {
          ...columns,
          options: options.filter((item) => {
            return !filterData.some((e) => e.field === item);
          }),
        },
      ]);
    };
     
    const handleFormatArray = () => {

        const formattedData = filterDataValue?.flatMap((item) => {
          const allCities = Array?.isArray(item?.anchorData?.outlet_details)
            ? item?.anchorData?.outlet_details?.map((outlet) => outlet?.city)
            : [];
          // console.log("filterDataValue",filterDataValue)
          return allCities?.map((city) => ({ 
            application_id: {
              name: item?.application_id,
              value: item?.application_id,
            },
            nbfc: {
              name: item?.nbfcData?.name,
              value: item?.nbfcData?.nbfc_id,
            },
            customer_name: {
              name: item?.customerData?.pan_details?.name,
              value: item?.customerData?.customer_id,
            },
            product_type: {
              name: item?.loan_type,
              value: item?.loan_type,
            },
            tenure: {
              name: item?.tenure_value,
              value: item?.tenure_value,
            },
            amount_roi: {
              name: item?.annual_interest_rate,
              value: item?.annual_interest_rate,
            },
            anchor: {
              name: item?.anchorData?.name,
              value: item?.anchorData?.anchor_id,
            },
            anchor_id: {
              name: item?.anchorData?.anchor_id,
              value: item?.anchorData?.anchor_id,
            },
            loan_amount: {
              name: item?.loan_amount,
              value: item?.loan_amount,
            },
            current_stage: {
              name: item?.current_stage_and_status?.stage,
              value: item?.current_stage_and_status?.stage,
            },
            current_status: {
              name: item?.current_stage_and_status?.status,
              value: item?.current_stage_and_status?.status,
            },
            sub_status: {
              name: item?.current_stage_and_status?.sub_status,
              value: item?.current_stage_and_status?.sub_Status,
            },
            overall_status: {
              name: item?.overall_status,
              value: item?.overall_status,
            },
            business_executive:{
              name:item?.business_executive,
              value:item?.business_executive
            },
            dealer_city:{
            name:city,
            value:city
            },
          
          }));
     
        });
        setSelectData(formattedData);
      };

    
    useEffect(() => {
      if (filterApplied) {
        handleFormatArray();
      }
    }, [filterDataValue, filterApplied]);

    const handleApply = (data) => {
      let finalFilter = {};
      (data ? data : filterData)?.map((item, index) => {
        if (item.field === "application_date") {
          finalFilter["application_start_date"] = item.value;
        } else if (item.field === "overall_status") {
          finalFilter[item.field] = item?.value;
        } else if (item.field === "current_stage") {
          finalFilter["current_stage"] = { stage: item?.value?.[0] };
        } else if (item.field === "current_status") {
          finalFilter["current_stage"] = {
            ...finalFilter["current_stage"],
            status: item?.value?.[0],
          };
        } else if (
          ["ready_for_credit_check", "credit_checker"].includes(item.field)
        ) {
          finalFilter[item.field] = item.value;
        } else if (item?.field === "anchor_id") {
          finalFilter["anchor"] = item.value?.map(
            (val) => val[item.field]?.value
          );
        }else if (item.field === "dealer_city") {
          finalFilter["dealer_city"] = item?.value?.map(
            (val) => val[item?.field]?.value
          )
        } else {
          finalFilter[item.field] = item.value?.map(
            (val) => val[item.field]?.value
          );
        }
      });
      onApply(finalFilter);
    };

    const removeItem = (i) => {
      if (filterData[i].field === "current_stage") {
        setFilterData([
          ...filterData.filter((item, index) => {
            if (
              index === i ||
              item.field === "current_status" ||
              item.field === "sub_status"
            )
              return false;
            return true;
          }),
        ]);
      } else
        setFilterData([...filterData.filter((item, index) => index !== i)]);
    };

    const handleChange = (key, value, i) => {
      // console.log("filter Data", filterData);

      let obj = { ...filterData[i] };
      obj = {
        ...obj,
        [key]: value,
      };

      if (key === "field") {
        obj = {
          ...obj,
          value: ["application_date"].includes(value)
            ? {
                from: Number(new Date()),
                to: Number(new Date()),
              }
            : [],
        };
      }

      filterData[i] = obj;
      if (key === "field") {
        setFilterData([...filterData.splice(0, i + 1)]);
        return;
      }

      setFilterData([...filterData]);
    };
   
    const handleReduceFilterValue = (init) => {
      let dropData = {};
      let finalArray = [...selectData];
      let filtered = init
        ? JSON.parse(localStorage.getItem(`filterData${name}`))
        : filterData;
      filtered?.map((item, index) => {
        if (
          item.field !== "" &&
          item.field !== "application_date" &&
          item?.value?.length > 0
        ) {
          finalArray = finalArray.filter((obj1) => {
            return item?.value?.some((obj2) => {
              if (item?.field === "overall_status") {
                return (item?.value || []).includes(
                  obj1?.[`${item?.field}`]?.value
                );
              } else if (
                item?.field === "current_stage" ||
                item?.field === "current_status" ||
                item?.field === "sub_status"
              ) {
                return obj1?.[`${item?.field}`]?.value === obj2;
              } else {
                return (
                  obj1?.[`${item?.field}`]?.value ===
                  obj2?.[`${item?.field}`]?.value
                );
              }
            });
          });
        }
        dropData = {
          ...dropData,
          [index + 1]: [...finalArray],
        };
      });
      // console.log("dropData", dropData);
      setReduceData(dropData);
    };

    useEffect(() => {
      if (filterApplied && filterData?.length && selectData?.length) {
        handleReduceFilterValue();
      }
    }, [selectData, filterData, filterApplied]);

    useEffect(() => {
      if (filterApplied) {
        // localStorage.setItem(`selectData${name}`, JSON.stringify(selectData));
        localStorage.setItem(`filterData${name}`, JSON.stringify(filterData));
        // localStorage.setItem(`reduceData${name}`, JSON.stringify(reduceData));
      }
    }, [filterData, filterApplied]);
    // }, [ reduceData ,selectData, filterData, filterApplied]);

    const handleStageData = (field, data) => {
      const mapData = [
        ...new Set(
          data?.map((item) => {
            return item?.[field]?.value;
          })
        ),
      ]?.map((item) => {
        return (
          <MenuItem value={item}>
            {(item || "")?.replaceAll("_", " ")?.toUpperCase()}
          </MenuItem>
        );
      });
      return mapData;
    };

    useImperativeHandle(ref, () => ({
      handeToggleFilter(filter) {
        filter =
          filter === "all" || filter === null ? overAllOptions : [filter];
        const index = filterData.findIndex(
          (item) => item.field === "overall_status"
        );
        if (index !== -1) {
          handleChange("value", filter, index);
          handleApply();
        } else {
          let data = [
            {
              field: "overall_status",
              value: filter,
              options: options,
            },
          ];
          setFilterData(data);
          handleApply(data);
        }
      },
    }));

    return (
      <>
        <div>
          <Modal
            show={open}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
          >
            <Modal.Body>
              {loading && <LoadingSkeleton />}
              {!loading && (
                <div className="sort-dialog">
                  <div className="sort-table-wrapper">
                    <table
                      className="sort-table app-sort-table"
                      id="app-sort-table"
                    >
                      <thead className="cf">
                        <tr>
                          <th style={{ width: 500 }}>Column</th>
                          <th style={{ width: "70%" }}>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterData?.map((col, i) => {
                          return (
                            <>
                              <tr>
                                <td data-title="Data Point">
                                  <Select
                                    className="w-100"
                                    value={col.field}
                                    sx={{
                                      boxShadow: "none",
                                      ".MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                      },
                                    }}
                                    onChange={(e) => {
                                      handleChange("field", e.target.value, i);
                                    }}
                                  >
                                    {col?.options?.map((item) => {
                                      return (
                                        <MenuItem value={item}>
                                          {(item || "")
                                            ?.replaceAll("_", " ")
                                            ?.toUpperCase()}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </td>
                                <td data-title="Operator">
                                  {col?.field !== "" &&
                                    (() => {
                                      if (col?.field === "application_date") {
                                        return (
                                          <DateRangePicker
                                            startDate={
                                              new Date(
                                                col?.value?.from || new Date()
                                              )
                                            }
                                            endDate={
                                              new Date(
                                                col?.value?.to || new Date()
                                              )
                                            }
                                            onChange={(start, end) => {
                                              handleChange(
                                                "value",
                                                {
                                                  from: Number(start),
                                                  to: Number(end),
                                                },
                                                i
                                              );
                                            }}
                                          />
                                        );
                                      } else if (
                                        col?.field === "overall_status"
                                      ) {
                                        return (
                                          <FormControl
                                            // variant="standard"
                                            sx={{ minWidth: "100%" }}
                                          >
                                            <Select
                                              multiple
                                              labelId="demo-simple-loan-standard-label"
                                              id="demo-simple-loan-standard"
                                              value={col.value}
                                              onChange={(e) => {
                                                // handleChange("value", ["all"], i);
                                                handleChange(
                                                  "value",
                                                  e.target.value,
                                                  i
                                                );
                                              }}
                                            >
                                              {i == 0
                                                ? handleStageData(
                                                    col?.field,
                                                    selectData
                                                  )
                                                : handleStageData(
                                                    col?.field,
                                                    reduceData?.[i]
                                                  )}
                                            </Select>
                                          </FormControl>
                                        );
                                      } else if (
                                        col?.field === "business_executive"
                                      ) {
                                        return (
                                          <FormControl
                                            // variant="standard"
                                            sx={{ minWidth: "100%" }}
                                          >
                                            <Select
                                              multiple
                                              multiline
                                              labelId="demo-simple-loan-standard-label"
                                              id="demo-simple-loan-standard"
                                              value={col.value}
                                              onChange={(e) => {
                                                handleChange(
                                                  "value",
                                                  e.target.value,
                                                  i
                                                );
                                              }}
                                              renderValue={(selected) => (
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                  {selected.map((value) => (
                                                    <span
                                                      key={value}
                                                      style={{
                                                        display: "inline-block",
                                                        padding: "2px 6px",
                                                        margin: "2px",
                                                        // border: "1px solid #ccc",
                                                        // borderRadius: "4px",
                                                      }}
                                                    >
                                                      {value}
                                                    </span>
                                                  ))}
                                                </div>
                                              )}
                                              // MenuProps={{
                                              //   PaperProps: {
                                              //     style: {
                                              //       width: 250,
                                              //     },
                                              //   },
                                              // }}
                                              maxRows={5}
                                            >
                                              {users.map(user=>(
                                                <MenuItem value={user.email}>
                                                  {user.email}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        );
                                      } else if (
                                        col?.field === "current_stage"
                                      ) {
                                        return (
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label=""
                                              value={col.value?.[0]}
                                              onChange={(x) => {
                                                handleChange(
                                                  "value",
                                                  [x.target.value],
                                                  i
                                                );
                                                if (
                                                  !!x.target.value &&
                                                  filterData[
                                                    filterData.length - 1
                                                  ].field === "current_stage"
                                                ) {
                                                  setFilterData([
                                                    ...filterData,
                                                    {
                                                      ...columns,
                                                      field: "current_status",
                                                      options: [
                                                        "current_status",
                                                        "sub_status",
                                                      ],
                                                    },
                                                  ]);
                                                } else if (
                                                  !x.target.value &&
                                                  filterData[i].field ===
                                                    "current_stage"
                                                ) {
                                                  setFilterData(
                                                    filterData.filter(
                                                      (item) =>
                                                        !(
                                                          item.field ===
                                                            "current_status" ||
                                                          item.field ===
                                                            "sub_status"
                                                        )
                                                    )
                                                  );
                                                }
                                              }}
                                            >
                                              {i == 0
                                                ? handleStageData(
                                                    col?.field,
                                                    selectData
                                                  )
                                                : handleStageData(
                                                    col?.field,
                                                    reduceData?.[i]
                                                  )}
                                            </Select>
                                          </FormControl>
                                        );
                                      } else if (
                                        col?.field === "current_status" ||
                                        col?.field === "sub_status"
                                      ) {
                                        return (
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label=""
                                              value={col?.value?.[0]}
                                              onChange={(x) => {
                                                handleChange(
                                                  "value",
                                                  [x.target.value],
                                                  i
                                                );
                                              }}
                                            >
                                              {handleStageData(
                                                col?.field,
                                                reduceData?.[i]
                                              )}
                                            </Select>
                                          </FormControl>
                                        );
                                      } else if (
                                        col?.field === "ready_for_credit_check"
                                      ) {
                                        return (
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label=""
                                              value={col?.value?.filter}
                                              onChange={(e) => {
                                                handleChange(
                                                  "value",
                                                  {
                                                    filter: e.target.value,
                                                  },
                                                  i
                                                );
                                              }}
                                            >
                                              <MenuItem value={true}>
                                                TRUE
                                              </MenuItem>
                                              <MenuItem value={false}>
                                                FALSE
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        );
                                      } else if (
                                        col?.field === "credit_checker"
                                      ) {
                                        return (
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label=""
                                              value={col?.value?.email}
                                              onChange={(e) => {
                                                handleChange(
                                                  "value",
                                                  {
                                                    email: e.target.value,
                                                  },
                                                  i
                                                );
                                              }}
                                            >
                                              {usersMail?.map((mail) => (
                                                <MenuItem value={mail}>
                                                  {mail}
                                                </MenuItem>
                                              ))}
                                              <MenuItem value={"not assigned"}>
                                                Not Assigned
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        );
                                      } else {
                                        return (
                                          <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={(() => {
                                              const allOptions = [
                                                ...new Map(
                                                  i === 0 &&
                                                  ![
                                                    "application_date",
                                                  ].includes(col.field)
                                                    ? selectData?.map(
                                                        (item) => [
                                                          item?.[col?.field]
                                                            ?.value,
                                                          item,
                                                        ]
                                                      )
                                                    : reduceData?.[i]?.map(
                                                        (item) => [
                                                          item?.[col?.field]
                                                            ?.value,
                                                          item,
                                                        ]
                                                      )
                                                ).values(),
                                              ].filter((itm) =>
                                                Boolean(
                                                  itm?.[col?.field]?.value
                                                )
                                              );
                                              return allOptions;
                                            })()}
                                            value={col?.value}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) =>
                                              option?.[col?.field]?.name
                                            }
                                            filterOptions={createFilterOptions({
                                              matchFrom: "start",
                                              stringify: (option) => {
                                                if (col?.field === "anchor") {
                                                  return `${
                                                    option?.[col?.field]
                                                      ?.name ||
                                                    "" +
                                                      option?.[col?.field]
                                                        ?.value ||
                                                    ""
                                                  }`;
                                                }
                                                return (
                                                  option?.[col?.field]?.name ||
                                                  ""
                                                );
                                              },
                                            })}
                                            renderOption={(
                                              props,
                                              option,
                                              { selected }
                                            ) => (
                                              <li
                                                {...props}
                                                key={
                                                  option?.[col?.field]?.value
                                                }
                                              >
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{ marginRight: 8 }}
                                                  checked={selected}
                                                />
                                                {option?.[col?.field]?.name +
                                                  (col?.field === "anchor"
                                                    ? " - " +
                                                      option?.[col?.field]
                                                        ?.value
                                                    : "")}
                                              </li>
                                            )}
                                            fullWidth
                                            onChange={(e, value) => {
                                              handleChange(
                                                "value",
                                                [...value],
                                                i
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />
                                        );
                                      }
                                    })()}
                                </td>
                                <td
                                  className="sort-close-td ps-1"
                                  style={{ width: "30px" }}
                                >
                                  {i !== 0 && (
                                    <CloseIcon
                                      onClick={() => removeItem(i)}
                                      sx={{ fontSize: 18 }}
                                    />
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>

                      <Button className="mb-3" onClick={addMore}>
                        Add More
                      </Button>
                    </table>
                  </div>

                  <div className="sort-bottom-holder">
                    <Button
                      className="me-2 clear-btn"
                      onClick={() => handleClose()}
                    >
                      Close
                    </Button>

                    <Button
                      variant="contained"
                      className="apply-btn"
                      onClick={() => handleApply()}
                    >
                      Apply Filters
                    </Button>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
);

export default LoanApplicationFilter;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

