import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import React, { useContext, useEffect, useState } from "react";
// import { DatePicker } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import DragDropDoc from "./FileViewer/DragDropDoc";
import { GlobalContext } from "../Context/GlobalContext";
import dayjs from "dayjs";
import { loanClosure } from "../api";
import useApplication from "../hooks/useApplication";
import LoadingOver from "./Loading";

function LoanClosure({ open, handleClose, isClosed }) {
  // const dragtl = gsap.timeline({ paused: true });
  const [cleared, setCleared] = React.useState(false);
  const { updateDetailState } = useContext(GlobalContext);
  const { getApplicationDetails } = useApplication();

  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [loanClosureData, setLoanClosureData] = useState({settlementDateMs:null});
  const [loading, setLoading] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  function isValid() {
    let valid = true;
    if (
      !loanClosureData?.settlementDateMs ||
      isNaN(loanClosureData?.closure_amount) ||
      !loanClosureData?.closure_type
    ) {
      valid = false;
    }
    return valid;
  }

  function buildPayload(newData, oldData) {
    if (!oldData) return newData;
    let payload = {};
    Object.keys(newData)?.map((item) => {
      if (item === "settlementDateMs") {
        if (
          !oldData?.[item] ||
          oldData?.[item] !== dayjs(newData?.[item]).valueOf()
        )
          payload[item] = dayjs(newData?.[item]).valueOf();
      }else if(item === "closure_amount" && oldData?.[item] !== newData?.[item]){
        payload[item]=newData?.[item]
      } else if (
        newData?.[item] &&
        (!oldData?.[item] || oldData?.[item] !== newData?.[item])
      ) {
        payload[item] = newData?.[item];
      }
    });
    return payload;
  }
  const disbursalDate1 = dayjs(updateDetails?.disbursal_date, "DD/MM/YYYY");

  async function handleSubmit() {
    if (!isValid()) return;
    if (
      dayjs(loanClosureData?.settlementDateMs).isBefore(
        dayjs(updateDetails?.disbursal_date, "DD/MM/YYYY")
      )
    ) {
      toast.error("closure date should be after disbursal date", {
        style: {
          width: 450,
        },
      });
      return;
    }
    if (dayjs(loanClosureData?.settlementDateMs).isAfter(dayjs())) {
      toast.error("closure date can't be future date", {
        style: {
          width: 380,
        },
      });
      return;
    }
    if(["settlement","foreclosure"].includes(loanClosureData?.closure_type) && loanClosureData?.closure_amount===0){
      toast.error("amount should be greater then 0");
      return;
    }
    try {
      setLoading("updating loan ...");
      let payload = {
        ...buildPayload(loanClosureData, updateDetails?.closure_details),
        application_id: updateDetails?.application_id,
      };
      const { data } = await loanClosure(payload);
      toast(data?.message);
      await getApplicationDetails(updateDetails?.application_id);
    } catch (error) {
      toast.error(error?.response?.data?.message || "failed to update !!!");
    } finally {
      handleClose();
      setIsEditable(true);
      setLoading(null);
    }
  }

  async function handleDelete(setDoc) {
    try {
      setLoading("deleting document");
      let payload = {
        delete_noc: true,
        application_id: updateDetails?.application_id,
      };
      const { data } = await loanClosure(payload);
      toast(data?.message);
      setDoc(null);
      await getApplicationDetails(updateDetails?.application_id);
    } catch (error) {
      toast.error(error?.response?.data?.message || "failed to delete !!!");
    } finally {
      setLoading(null);
    }
  }

  async function handleReupload(base64, dataType) {
    try {
      setLoading("reuploading file....");
      let payload = {
        noc_file: base64,
        application_id: updateDetails?.application_id,
      };
      const { data } = await loanClosure(payload);
      toast("reuploaded doc successfully !!!");
      await getApplicationDetails(updateDetails?.application_id);
    } catch (error) {
      toast.error(error?.response?.data?.message || "failed to replaod !!!");
    } finally {
      setLoading(null);
    }
  }
  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  useEffect(() => {
    if (updateDetails?.closure_details || open) {
      let date = updateDetails?.closure_details?.settlementDateMs || null;
      setLoanClosureData({
        ...updateDetails?.closure_details,
        settlementDateMs: date ? dayjs(date) : null,
      });
    }
  }, [updateDetails?.closure_details, open]);

  useEffect(() => {
    if (isClosed) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [isClosed, open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          setIsEditable(true);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                height: "100%",
              }}
            >
              {"Loan Closure"}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {isClosed && (
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    height: 30,
                  }}
                  onClick={() => {
                    setIsEditable((prev) => !prev);
                  }}
                  color={isEditable ? "primary" : "error"}
                >
                  Edit{" "}
                  {!isEditable ? (
                    <CloseIcon fontSize="14px" />
                  ) : (
                    <EditIcon fontSize="12px" />
                  )}
                </Button>
              )}
              <IconButton
                aria-label="close"
                onClick={() => {
                  handleClose();
                  setIsEditable(true);
                }}
                sx={{
                  color: "rgba(35, 35, 35, 1)",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <CloseIcon style={{ fontSize: 28 }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container className="w-100">
            <Grid item xs={3.5} className="mb-4">
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                }}
                className="font-inter"
              >
                Closure Date{" "}
                <span
                  style={{
                    color: "rgba(255, 34, 34, 1)",
                  }}
                >
                  *
                </span>
              </h6>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <DemoItem>
                      <DatePicker
                        sx={{ width: 165 }}
                        slotProps={
                          {
                            // textField: {
                            //   helperText: error.settlementDateMs,
                            // },
                            // field: {
                            //   clearable: true,
                            //   onClear: () => setCleared(true),
                            // },
                          }
                        }
                        format="DD/MM/YYYY"
                        minDate={
                          updateDetails?.disbursal_date
                            ? dayjs(disbursalDate1)
                            : ""
                        }
                        disableFuture
                        value={loanClosureData?.settlementDateMs}
                        disabled={isEditable}
                        inputVariant="outlined"
                        onChange={(date) => {
                          setLoanClosureData((prev) => ({
                            ...prev,
                            settlementDateMs: date,
                          }));
                        }}
                        animateYearScrolling
                      />
                    </DemoItem>
                  </Box>
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={12} className="mb-4">
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                }}
                className="font-inter"
              >
                Closure Type{" "}
                <span
                  style={{
                    color: "rgba(255, 34, 34, 1)",
                  }}
                >
                  *
                </span>
              </h6>
              <div
                style={{
                  width: "30%",
                }}
              >
                <FormControl disabled={isEditable} fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={loanClosureData?.closure_type}
                    onChange={(event, ele) => {
                      setLoanClosureData((prev) => ({
                        ...prev,
                        closure_type: ele?.props?.value,
                      }));
                    }}
                  >
                    <MenuItem value={"normal"}>Normal</MenuItem>
                    <MenuItem value={"settlement"}>Settlement</MenuItem>
                    <MenuItem value={"foreclosure"}>Foreclosure</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} className="mb-4">
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                }}
                className="font-inter"
              >
                Closure Amount{" "}
                <span
                  style={{
                    color: "rgba(255, 34, 34, 1)",
                  }}
                >
                  *
                </span>
              </h6>
              <div
                style={{
                  width: "30%",
                }}
              >
                <TextField
                  className="w-100"
                  id="outlined-multiline-static"
                  value={loanClosureData?.closure_amount ?? ""}
                  helperText={["settlement","foreclosure"].includes(loanClosureData?.closure_type) && loanClosureData?.closure_amount===0  ? <span style={{ color: "red" }}>{"value should be > 0"}</span> : null}
                  onChange={(e) => {
                    const isDigit = /\d+/g.test(e.target.value);
                    let amount = parseInt(e.target.value)
                    if(!isDigit) amount=0;
                    setLoanClosureData((prev) => ({
                      ...prev,
                      closure_amount: amount,
                    }));
                  }}
                  disabled={isEditable}
                />
              </div>
            </Grid>
            <Grid item xs={12} className="mb-4">
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                }}
                className="font-inter"
              >
                Image of the NOC
              </h6>
              <div>
                <DragDropDoc
                  noc={loanClosureData?.noc}
                  onChange={(base64) => {
                    if (base64 === "delete") {
                      setLoanClosureData((prev) => ({
                        ...prev,
                        delete_noc: true,
                      }));
                    } else {
                      setLoanClosureData((prev) => ({
                        ...prev,
                        noc_file: base64,
                        delete_noc: null,
                      }));
                    }
                  }}
                  handleDelete={handleDelete}
                  handleReupload={handleReupload}
                  disabled={isEditable}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                }}
                className="font-inter"
              >
                Comments
              </h6>
              <TextField
                className="w-100"
                id="outlined-multiline-static"
                multiline
                rows={4}
                //   placeholder="Write your Comments here."
                value={loanClosureData?.comment}
                onChange={(e) => {
                  setLoanClosureData((prev) => ({
                    ...prev,
                    comment: e.target.value,
                  }));
                }}
                disabled={isEditable}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            disabled={!isValid() || isEditable}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <LoadingOver text={loading} />}
    </>
  );
}

export default LoanClosure;
