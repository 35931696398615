import { Button, InputAdornment, TextField } from "@mui/material";
import moment from "moment/moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchAllApplications2, fetchedLoadFilteredValues } from "../../api";
import CustomTable from "../../Components/CustomTable/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import {
  Approved,
  Closed,
  DisbursalRejected,
  Disbursed,
  Pending,
  Rejected,
} from "../../Components/StatusChip";
import SideNavBar from "../../Components/SideNavBar/SideNavBar";
import { useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DownloadTable from "../../Components/Download/TableExport";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoanApplicationFilter from "../../Components/Sort/LoanApplicationsFilterV2";
import { UserContext } from "../../Context/UserContext";
import DownloadIcon from "@mui/icons-material/Download";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { toast } from "react-toastify";
import StatsDashboardPopup from "../../Components/StatsDashboardPopup";
import SummarizeIcon from '@mui/icons-material/Summarize';
import CibilDashboardPopup from "../../Components/CibilDashboardPopup";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { capitalize, f } from "../../utility/Formater";

const columnsRemovedForNBFC = {
  "Application Date": 1,
  NBFC: 1,
  Status: 1,
  // "Overall Status":1,
  Stage: 1,
  agent: 1,
};

export const LoanApplicationsTable = () => {
  const [tableData, setTableData] = useState([]);
  const [totalCount, setCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const navigate = useNavigate();
  const [filter, setFilter] = useState("all");
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [showStats, setShowStats] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  const filterDialogRef = useRef();
  const [filterApplied, setFilterApplied] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [sort, setSort] = useState({
    sortField: null,
    sortType: null,
  });
  const [filterData, setFilterData] = useState("all");
  const { user, setUser } = useContext(UserContext);
  const [openCibilPopup, setOpenCibilPopup] = useState(false);
  // const [cibilData, setCibilData] = useState("");
  const [isEdit, setIsEdit] = useState(false);


  const handleFilter = (event, value) => {
    console.log(filterDialogRef.current);
    setFilter(value);
    if (!filterApplied) {
      setTimeout(() => {
        filterDialogRef.current &&
          filterDialogRef.current.handeToggleFilter(value);
      }, 500);
      setFilterApplied(true);
    } else {
      filterDialogRef.current &&
        filterDialogRef.current.handeToggleFilter(value);
    }

    // localStorage.setItem('togglefiltered', [value]);
    // setFilter(value);
    // if (value === "all") {
    //   fetchTableData(1, 100);
    // }
    // else fetchTableData(1, 100, "filter", encodeURIComponent(JSON.stringify({overall_status:[value]})));
    // setFilterData((prev)=>( {...prev , overall_status:[value]} ));
  };

  const statusChipFunc = (edx) => {
    if (edx?.embifi_approval_details?.status) {
      if (edx?.is_disbursed) {
        if (edx?.is_closed) {
          return <Closed />;
        } else {
          return <Disbursed />;
        }
      } else if (edx?.is_disbursed === null) {
        return <Approved />;
      } else {
        return <DisbursalRejected />;
      }
    } else if (edx?.embifi_approval_details?.status === null) {
      return <Pending />;
    } else {
      return <Rejected />;
    }
  };

  let columns = [
    { 
      label: <span className="text-nowrap" >Business Executive</span>, 
      render:(i)=>{
        const replacedEmail = tableData[i]?.additional_stages?.sales_agent?.[0]?.email?.replace(/\./g, " ");
        const sanitizedEmail = replacedEmail?.replace(/@.*$/, "");
        const sales_agent_name = capitalize(sanitizedEmail);
        return f(capitalize(sales_agent_name))
      },
      width:150
    },
    // { label: "Application ID", value: "application_id", copy: true },
    { label: "Dealership Name", 
      // value: "anchorData.dealership_name", 
      render: (i) => {
        return (
          <div className="details " style={{ flex: 1 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: '280px' }}>
                {tableData[i]?.anchorData?.dealership_name}
              </div>
            </div>
          <div style={{ fontSize: 12, display: "flex" }} className="my-1">
            <div style={{ width: 100, display: "flex" }}>
                <PhoneIcon
                  className="me-1 "
                  style={{ fontSize: 12, marginTop: 3 }}
                />
                {tableData[i]?.anchorData?.outlet_details?.[0]?.relation_manager_details?.[0]?.mobile || "NA"}
              </div>
              {tableData[i]?.master_city && <div className="d-flex">
                <LocationOnIcon
                  style={{ fontSize: 12, marginTop: 3 }}
                />
                {tableData[i]?.master_city}
              </div>}
            </div>
          </div>
        )
      },
      width: 200 
    },
    {
      label: "Application Date",
      render: (i) => {
        return tableData[i]?.application_start_date
          ? moment(tableData[i]?.application_start_date).format("DD-MM-YYYY")
          : "";
      },
      value: "application_start_date",
      isSortable: true,
      width: 130,
    },
    {
      label: "Customer Name",
      value: "customerData.pan_details.name",
      render: (i) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
          <div className="details" style={{ flex: 1 }}>
            <div className="d-flex flex-column align-items-start justify-content-start">
              <span style={{fontSize:12}} className="pointer" onClick={()=>{
                navigator.clipboard.writeText(
                  tableData[i]?.application_id
                );
                toast.success("Copied to Clipboard", {
                  position: "top-center",
                  autoClose: 500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }} >{tableData[i]?.application_id}</span>
              <div style={{ width: '280px', fontWeight:"500" }}>
                {tableData[i]?.customerData?.pan_details?.name ||
                  tableData[i]?.customerData?.corporate_pan_details?.name}
              </div>
            </div>
            <div style={{ fontSize: 12, display: "flex" }} className="my-1">
              <div style={{ width: 100, display: "flex" }}>
                <PhoneIcon
                  className="me-1"
                  style={{ fontSize: 12, marginTop: 3 }}
                />
                {tableData[i]?.customerData?.mobile_number || "NA"}
              </div>
              <div className="d-flex">
                <LocationOnIcon
                  className="ms-2"
                  style={{ fontSize: 12, marginTop: 3 }}
                />
                {tableData[i]?.customerData?.residential_address?.city || tableData[i]?.customerData?.residential_address?.district
                  ? `${tableData[i]?.customerData?.residential_address?.city || ""}${tableData[i]?.customerData?.residential_address?.city && tableData[i]?.customerData?.residential_address?.district ? ", " : ""}${tableData[i]?.customerData?.residential_address?.district || ""}`
                  : "NA"}
              </div>
            </div>
          </div>
          <div className="image">
            <embed
              src={tableData[i]?.nbfcData?.logo}
              height={'100%'}
              width={50}
            />
          </div>
        </div>
        
        );
        // return statusChipFunc(tableData[i]);
      },
      isSortable: true,
      width: 350,
    },
    {
      label: "Overall Status",
      render: (i) => {
        return statusChipFunc(tableData[i]);
      },
      width: 140,
    },
    {
      label: "Loan Amount",
      render: (i) => {
        return tableData[i]?.loan_amount
          ? "₹" + tableData[i]?.loan_amount
          : "₹ 0.00";
      },
      value: "loan_amount",
      isSortable: true,
      width: 100,
    },
    { label: "Amount ROI(%)", value: "annual_interest_rate", width: 100 },
    {
      label: "Stage",
      render: (i) => {
        return window.location.href.includes("www.embedded")
          ? tableData[i]?.application_id?.slice(-4) >= 565
            ? tableData[i]?.current_stage_and_status?.stage?.replaceAll(
                "_",
                " "
              )
            : ""
          : tableData[i]?.current_stage_and_status?.stage?.replaceAll("_", " ");
      },
      value: "annual_interest_rate",
      width: 150,
    },
    {
      label: "Status",
      render: (i) => {
        return window.location.href.includes("www.embedded")
          ? moment(tableData[i]?.application_start_date).isSameOrAfter(
              `2023-01-06T09:49:55.181Z`
            )
            ? tableData[i]?.current_stage_and_status?.status?.replaceAll(
                "_",
                " "
              )
            : ""
          : tableData[i]?.current_stage_and_status?.status?.replaceAll(
              "_",
              " "
            );
      },
      value: "annual_interest_rate",
    },
    {
      label: "Tenure",
      render: (i) => {
        return tableData[i]?.tenure_value !== null
          ? tableData[i]?.tenure_value + " " + tableData[i]?.tenure_type
          : "-";
      },
      value: "tenure_value",
      width: 100,
    },
    { label: "Dealer", value: "anchorData.name", width: 200 },
    { label: "Product Type", value: "loan_type", width: 100 },
    // { label: "NBFC", value: "nbfcData.name", width: 250 },
    { label: "agent", value: "agent_code" },
    { 
      label: <span className="text-nowrap" >Vehicle Model</span>, 
      render:(i)=>{
        return tableData[i]?.other_details?.vehicle_model ?? "-"
      },
      width:200
    },
    {
      label:<span className="text-nowrap" >Brand Name</span> ,
      render:(i)=>{
        return tableData[i]?.oemData?.brand_name ?? "-"
      },
      width:200
    },
  ];

  columns = columns.concat([
    {
      label: "Action",
      render: (index) => {
        return (
          <Button
            onClick={() => {
              navigate(`/view-application/${tableData[index].application_id}`);
            }}
          >
            view
          </Button>
        );
      },
    },
  ]);

  if (Number(user.role) === 3) {
    columns = columns.filter((item) => !columnsRemovedForNBFC?.[item?.label]);
  }

  // fetchTableData(page, limit, "sort", null, sortValue, sortField);

  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    search,
    pageChange
  ) => {
    if (pageChange) setPageLoading(true);
    else setLoading(true);
    try {
      typeValue = typeof typeValue === "object" ? typeValue : {};

      if (typeValue?.hasOwnProperty("overall_status")) {
      }

      let params = `&page=${page}`;
      if (limit) params += `&limit=${limit}`;

      if(user?.isUserPresenter){
        type="filter";
        typeValue = {
          ...typeValue,
          application_start_date:{"from":moment().subtract(40,"days").valueOf(),"to":Date.now()}
        }
      }

      if (type === "filter")
        params += `&filters=${encodeURIComponent(
          JSON.stringify(typeValue).toString()
        )}`;
      if (search) params += `&query=${search}`;
      if (sort?.sortField)
        params += `&sortField=${sort?.sortField}&sortType=${sort?.sortType}`;

      let { data } = await fetchAllApplications2(params);
      setTableData(data?.data?.[0]?.applications || []);
      setCount(data?.data?.[0]?.count);
    } catch (error) {}
    if (pageChange) setPageLoading(false);
    else setLoading(false);
  };

  const IntialDataFetch = () => {
    if (localStorage.getItem("loanApplicationFilter")) {
      setFilterApplied(true);
      fetchTableData(
        page ?? 1,
        50,
        "filter",
        JSON.parse(localStorage.getItem("loanApplicationFilter"))
      );
    } else {
      fetchTableData(page ?? 1, 50);
    }
  };

  useEffect(() => {
    IntialDataFetch();
  }, []);

  useEffect(() => {
    let filterData =
      JSON.parse(localStorage.getItem("filterDataLoanApplications")) || [];
    const index = filterData.findIndex(
      (item) => item.field === "overall_status"
    );

    if ((filterData?.[index]?.value || []).length === 1)
      setFilter(filterData?.[index]?.value?.[0]);
    else if ((filterData?.[index]?.value || []).length === 5) setFilter("all");
    else setFilter(null);

    if (index < 0) setFilter("all");
  }, [localStorage.getItem("filterDataLoanApplications")]);

  useEffect(() => {
    if (sort?.sortField) {
      fetchTableData(
        page,
        limit,
        localStorage.getItem("loanApplicationFilter") ? "filter" : null,
        JSON.parse(localStorage.getItem("loanApplicationFilter")),
        isSearch ? searchTxt : null,
        true
      );
    }
  }, [sort]);

  let timeOut;

  const handleSearch = (value) => {
    if (value !== "") {
      setSearch(true);
      setSearchTxt(value);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("loanApplicationFilter") ? "filter" : null,
        JSON.parse(localStorage.getItem("loanApplicationFilter")),
        value
      );
    } else {
      setSearchTxt("");
      setSearch(false);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("loanApplicationFilter") ? "filter" : null,
        JSON.parse(localStorage.getItem("loanApplicationFilter"))
      );
    }
  };
  
  return (
    <>
      <div className="px-3">
        {/* {!isLoading && ( */}
        <div className="d-flex p-3" style={{ justifyContent: "space-between" }}>
          <div className="d-flex flex-row align-items-center">
            <TextField
              className="search-bar-table me-2"
              id="input-with-icon-textfield"
              placeholder="Search"
              variant="standard"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "gray" }} />,
                disableUnderline: true,
                style: { color: "black", fontWeight: 400, height: 25 },
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e.target.value);
                }
              }}
            />
            {/* <ToggleButtons /> */}
            {!(filterApplied && localStorage.getItem("loanApplicationFilter")) ? (
              Number(user?.role) !== 3 && (
                <Button
                  className="mx-3"
                  sx={{ color: "gray" }}
                  onClick={() => {
                    setFilterApplied(true);
                    setOpenFilter(true);
                  }}
                >
                  <FilterAltIcon fontSize="small" /> Filters
                </Button>
              )
            ) : (
              <div style={{ paddingRight: "15px" }}>
                <Button
                  onClick={() => {
                    setFilterApplied(true);
                    setOpenFilter(true);
                  }}
                >
                  <span className="zoom-animation d-flex flex-row align-items-center">
                    <FilterAltIcon fontSize="small" /> Filters
                  </span>
                </Button>
                <span
                  className="pointer mx-3"
                  onClick={() => {
                    localStorage.removeItem(`selectDataLoanApplications`);
                    localStorage.removeItem(`filterDataLoanApplications`);
                    // localStorage.removeItem(`reduceDataLoanApplications`);
                    localStorage.removeItem("loanApplicationFilter");
                    fetchTableData(1, 50, null, null, null, true);
                    setFilterApplied(false);
                    setOpenFilter (false);
                  }}
                >
                  <HighlightOffIcon style={{ fontSize: 20 }} />
                </span>
              </div>
            )}

            {/* Status Button Group(s) */}
            {/* <ToggleButtonGroup
              value={filter}
              exclusive
              onChange={handleFilter}
              aria-label="text alignment"
              size="small"
              className="toggle-btn-holder"
            >
              <ToggleButton
                value="all"
                aria-label="left aligned"
                className="toggle-btn"
              >
                All
              </ToggleButton>
              <ToggleButton
                value="approved"
                aria-label="centered"
                className="toggle-btn"
              >
                Approved
              </ToggleButton>
              {Number(user?.role) !== 3 && (
                <ToggleButton
                  value="rejected"
                  aria-label="right aligned"
                  className="toggle-btn"
                >
                  Rejected
                </ToggleButton>
              )}
              {Number(user?.role) !== 3 && (
                <ToggleButton
                  value="pending"
                  aria-label="justified"
                  className="toggle-btn"
                >
                  Pending
                </ToggleButton>
              )}
              <ToggleButton
                value="disbursed"
                aria-label="justified"
                className="toggle-btn"
              >
                Disbursed
              </ToggleButton>
              {Number(user?.role) !== 3 && (
                <ToggleButton
                  value="closed"
                  aria-label="justified"
                  className="toggle-btn"
                >
                  Closed
                </ToggleButton>
              )}
            </ToggleButtonGroup> */}
          </div>
          <div>
              {!user?.isUserPresenter && <Button
                className="pointer"
                style={{ color: "gray" }}
                onClick={() => {
                 setOpenCibilPopup(true);
                 setIsEdit(false)
                }}
              >
                <SummarizeIcon className="me-1" />
                CIBIL Report
              </Button>}
            {Number(user?.role) !== 3 && (
              <Button
                className="pointer"
                style={{ color: "gray" }}
                onClick={() => {
                  setShowStats(true);
                }}
              >
                <AssessmentOutlinedIcon className="me-1" />
                Stats
              </Button>
            )}
            {Number(user?.role) !== 3 && (
              <Button
                className="me-3 pointer"
                style={{ color: "gray" }}
                onClick={() => {
                  setOpenExport(true);
                }}
              >
                <DownloadIcon className="me-1" />
                Export
              </Button>
            )}
          </div>
        </div>
        {/* )} */}
        <CustomTable
          tableData={tableData}
          columns={columns}
          defaultLimit={50}
          totalCount={totalCount}
          isLoading={isLoading}
          // serialNo={true}
          allowAll={true}
          pageLoading={isPageLoading}
          onPageChange={(page, limit) => {
            setLimit(limit || 50);
            setPage(page || 1);
            fetchTableData(
              page,
              limit,
              localStorage.getItem("loanApplicationFilter") ? "filter" : null,
              JSON.parse(localStorage.getItem("loanApplicationFilter")),
              isSearch ? searchTxt : null,
              true
            );
          }}
          onLimitChange={(page, limit) => {
            setLimit(limit || 50);
            setPage(1);
            fetchTableData(
              1,
              limit,
              localStorage.getItem("loanApplicationFilter") ? "filter" : null,
              JSON.parse(localStorage.getItem("loanApplicationFilter")),
              isSearch ? searchTxt : null
            );
          }}
          onSortChange={(page, limit, sortValue, sortField) => {
            setLimit(limit || 50);
            setPage(page || 1);
            setSort({
              sortField,
              sortType: sortValue,
            });
            // fetchTableData(page, limit, "sort", null, sortValue, sortField);
          }}
          onDoubleClick={(data) => {
            navigate(`/view-application/${data.application_id}`);
          }}
        />

        {/* Filter Modal */}
        <LoanApplicationFilter
          open={openFilter}
          ref={filterDialogRef}
          filterApplied={filterApplied}
          name={"LoanApplications"}
          handleClose={() => setOpenFilter(false)}
          onApply={(filter) => {
            localStorage.setItem(
              "loanApplicationFilter",
              JSON.stringify(filter)
            );
            fetchTableData(1, limit, "filter", filter, null, true);
            setOpenFilter(false);
          }}
          options={[
            "overall_status",
            "application_id",
            !user?.isUserPresenter && "application_date",
            "customer_name",
            "loan_amount",
            "amount_roi",
            "tenure",
            "anchor",
            "anchor_id",
            "product_type",
            "nbfc",
            "current_stage",
            "ready_for_credit_check",
            "credit_checker",
            "business_executive",
            "dealer_city"
          ].filter(Boolean)}
        />
      </div>

      <DownloadTable
        open={openExport}
        type={"loan-applications"}
        filterData={localStorage.getItem("loanApplicationFilter") || null}
        handleClose={() => setOpenExport(false)}
      />
      <StatsDashboardPopup open={showStats} handleClose={()=>{setShowStats(false)}} />

      <CibilDashboardPopup
        open={openCibilPopup}
        openCibilPopup={openCibilPopup}
        setOpenCibilPopup={setOpenCibilPopup}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </>

  );
};
