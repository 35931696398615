import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useApplication from "../hooks/useApplication";
import { f } from "../utility/Formater";
import CustomTableV2 from "./CustomTable/CustomTableV2";
import AddNachPresent from "./AddNachPresent";
import { Delete } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import { toast } from "react-toastify";
import { changeNachPresentStatus, getNachPresentData } from "../api";
import moment from "moment";
import MouseOverPopover from "./Popover";
import { NachPresentFailure } from "../Pages/Tables/ViewCollection/ViewCollectionsV2";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { UserContext } from "../Context/UserContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function NachPresent({
  open,
  handleClose,
  latestNachPresentData,
  setLatestNachPresentData,
  nachPresentColors = {},
}) {
  const { updateDetails } = useApplication();
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [openAddNach, setOpenAddNach] = useState(false);
  const [nachPresentIdx, setNachPresentIdx] = useState(-1);
  const [confCncl, setConfCncl] = useState({ index: -1, open: false });
  const { user } = useContext(UserContext);

  async function handleRefeshNachPresent(index) {
    try {
      setNachPresentIdx(index);
      let params = `${updateDetails?.application_id}/${updateDetails?.nbfc_id}/${tableData?.[index]?.id}`;
      const { data } = await getNachPresentData(params);
      toast("Nach present is " + data?.data?.nach_txn_details?.state);
      if(data?.data?.id === latestNachPresentData?.id){
        setLatestNachPresentData(data?.data);
      }
      tableData[index] = data?.data;
      setTableData(tableData);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setNachPresentIdx(-1);
    }
  }

  async function handleCancelNachPresent() {
    try {
      closeConfCancel();
      let index = confCncl?.index;
      setNachPresentIdx(index);
      let payload = {
        application_id: updateDetails?.application_id,
        nach_id: tableData?.[index]?.id,
        action: "Cancel",
        nbfc_id: updateDetails?.nbfc_id,
      };
      const { data } = await changeNachPresentStatus(payload);
      toast(data?.message);
      tableData[index] = data?.data;
      if(data?.data?.id === latestNachPresentData?.id){
        setLatestNachPresentData(data?.data);
      }
      setTableData(tableData);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setNachPresentIdx(-1);
    }
  }

  function closeConfCancel() {
    setConfCncl((prev) => ({ ...prev, open: false }));
  }

  let columns = [
    {
      label:<span className="text-nowrap" >Schedule Id</span>,
      render: (index) => {
        return f(tableData?.[index]?.id);
      },
    },
    {
      label: <span className="text-nowrap" >Amount</span>,
      render: (index) => {
        return f(tableData?.[index]?.amount);
      },
    },
    {
      label: <span className="text-nowrap" >Presentation Date</span>,
      render: (index) => {
        const date = tableData?.[index]?.created_at
        return date ? moment(date,"YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") :f(null);
      },
      width: 150,
    },
    {
      label: <span className="text-nowrap" >Settlement Date</span>,
      render: (index) => {
        const date = tableData?.[index]?.nach_txn_details?.scheduled_settlement_date
        return date ? moment(date,"YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") :f(null);
      },
      width: 150,
    },
    {
      label: "State",
      render: (index) => {
        let state = tableData?.[index]?.nach_txn_details?.state;
        return (
          <span
            style={{ color: nachPresentColors?.[state],textTransform:"capitalize" }}
            className="d-flex flex-row align-items-center"
          >
            {f(state)}
            {Boolean(tableData?.[index]?.nach_txn_details?.details?.failure_reason) && <MouseOverPopover className="ms-1" renderUI={<NachPresentFailure noAmount={true} nachFailureDetails={tableData?.[index]?.nach_txn_details?.details} />} head={<InfoOutlinedIcon fontSize='small' sx={{fontSize:16}} />} />}
          </span>
        );
      },
    },
    {
      label: <span className="text-nowrap" >Presented By</span>,
      render: (index) => {
        let presented_by = tableData?.[index]?.presented_by;
        const replacedEmail = presented_by?.replace(/\./g, " ");
        const sanitizedEmail = replacedEmail?.replace(/@.*$/, "");
        return (
          <span
            className="d-flex flex-row align-items-center text-capitalize text-nowrap"
          >
            {f(sanitizedEmail)}
          </span>
        );
      },
    },
  ];

  columns.push({
    label: "Action",
    render: (index) => {
      return (
        <div className="d-flex flex-row align-items-center">
          <IconButton
            className={nachPresentIdx === index ? "rotate-class" : ""}
            size="small"
            color="primary"
            disabled={nachPresentIdx === index}
            onClick={() => {
              handleRefeshNachPresent(index);
            }}
          >
            <CachedIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            disabled={
              nachPresentIdx === index ||
              tableData?.[index]?.status !== "active"
            }
            onClick={() => {
              setConfCncl({ index, open: true });
            }}
            color="error"
          >
            <Delete fontSize="small" />
          </IconButton>
        </div>
      );
    },
    width: 100,
  });
  function sortTblDataByCurrentMonthThenSettlementDate(tblData) {
    const currMonth = new Date()?.getMonth();
    const currYear = new Date()?.getFullYear();
  
    return tblData.sort((a, b) => {
      const dateA = new Date(a?.nach_txn_details?.scheduled_settlement_date);
      const dateB = new Date(b?.nach_txn_details?.scheduled_settlement_date);
  
      const isCurrentMonthA = dateA?.getFullYear() === currYear && dateA?.getMonth() === currMonth;
      const isCurrentMonthB = dateB?.getFullYear() === currYear && dateB?.getMonth() === currMonth;
  
      if (isCurrentMonthA && !isCurrentMonthB) return -1;
      if (!isCurrentMonthA && isCurrentMonthB) return 1;
  
      return dateB - dateA; // Descending by date
    });
  }
  

  useEffect(() => {
    if (updateDetails?.application_id){
      let tblData = updateDetails?.collectionData?.nach_presentation_data || [];
      tblData?.filter(item=>item);
      const sortedData = sortTblDataByCurrentMonthThenSettlementDate(tblData);
      setTableData(sortedData);
    }
  }, [updateDetails]);

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        open={open}
        onClose={handleClose}
        maxWidth={"lg"}
      >
        <DialogTitle sx={{ backgroundColor: "#EFF5FF" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h6>{updateDetails?.customer_name}</h6>
              <h6>{updateDetails?.application_id}</h6>
            </div>
            <span>
              {Number(user?.role)!==3 && <Button
                variant={"contained"}
                onClick={() => {
                  setOpenAddNach(true);
                }}
                disabled={false}
              >
                <Add /> Present Nach
              </Button>}
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </span>
          </div>
        </DialogTitle>
        <DialogContent sx={{ width: "100%", px: 0 , pb:1}}>
          <CustomTableV2
            tableData={tableData}
            tableHeight={430}
            columns={columns}
            limit={0}
            page={1}
            isLoading={tableLoading}
            serialNo={true}
          />
        </DialogContent>
      </Dialog>
      <AddNachPresent
        open={openAddNach}
        handleClose={() => {
          setOpenAddNach(false);
        }}
        tableData={tableData}
      />
      <Dialog
        open={confCncl?.open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        onClose={closeConfCancel}
      >
        <DialogTitle>
          {"Are you sure you want to cancel nach for this customer?"}
        </DialogTitle>
        <DialogActions>
          <Button variant="text" onClick={closeConfCancel}>
            Go Back
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleCancelNachPresent();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NachPresent;
