import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Close, FileDownload, InsertDriveFile } from "@mui/icons-material";
import { ProgressBar } from "react-bootstrap";
import DragDrop from "../DocumentUploader";
import BulkAssignExecutivesStatus from "./BulkAssignExecutivesStatus";

const BulkAssignExecutives = ({
  open,
  onClose,
  handleSubmitFile,
  failedRows,
  successRows,
  handleDownloadSampleFile
}) => {
  const [file, setFile] = useState(null);
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState(null);
  const [showStatus, setShowStatus] = useState(false);

  const handleFileSubmission = async (file) => {
    setUploading(true);
    try {
      setProgress(50);
      await handleSubmitFile(file); 
      setProgress(100); 
      setShowStatus(true);
    } catch (error) {
      console.error('Error during file submission:', error);
      setProgress(0); 
    } finally {
      setUploading(false); // Ensure uploading flag is reset
    }
  };

  return (
    <>
    <Dialog
      open={open}
      maxWidth="xs" // Reduced dialog width
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Bulk Assign Executives
        </Typography>
        <Button
          variant="outlined"
          onClick={handleDownloadSampleFile}
          sx={{
            padding: "5px 10px",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          <FileDownload sx={{ marginRight: 1 }} />
          Sample
        </Button>
        <IconButton onClick={() => {
        setFileName(null)
        setFile(null)
        onClose()}}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isUploading ? (
          <Box sx={{ textAlign: "center" }}>
            <ProgressBar
              striped
              variant="success"
              label={`${progress.toFixed(2)}%`}
              now={progress}
            />
            {progress === 100 && <Typography variant="body2">Upload Complete!</Typography>}
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {file === null ? (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <DragDrop
                  customType={["XLSX"]}
                  setFile={(val, name) => {
                    console.log('val', val);
                    console.log('name', name);
                    setFileName(name);
                    setFile(val)
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InsertDriveFile sx={{ marginRight: 2 }} /> {fileName}
                <Typography variant="body2">{file.name}</Typography>
                <IconButton onClick={() => {
                  setFile(null)
                  setUploading(false)}
                }>
                  <Close sx={{ color: "red" }} />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={() => {
          setUploading(false)
          onClose();
        }} color="secondary">
          Cancel
        </Button>
        {!isUploading && (
          <Button
            variant="contained"
            color="primary"
            onClick={()=> {
              setUploading(true);
              // setShowStatus(true)
              setFile(null);
              setFileName(null);
              handleFileSubmission(file)}}
            disabled={file === null}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
    <BulkAssignExecutivesStatus 
      open={showStatus}
      successRows={successRows}
      failedRows={failedRows}
      onClose={() => {setShowStatus(false)
        onClose()}
      }
    />
    </>
  );
};

export default BulkAssignExecutives;
