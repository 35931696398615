import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { FileDownload as FileDownloadIcon, Close as CloseIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import emptyImg from "../../assets/empty.png"

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const BulkAssignExecutivesStatus = ({
  open,
  successRows = [],
  failedRows = [],
  onClose
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        <span>Bulk Assign Status</span>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={`Success (${successRows.length})`} />
            <Tab label={`Failed (${failedRows.length})`} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {successRows.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <img src={emptyImg} alt="No Success" width="150" />
                <Typography variant="body2" color="textSecondary">
                  No successful assignments.
                </Typography>
              </div>
            ) : (
              <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Anchor ID</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {successRows.map((row, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{row.anchor_id}</td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{row.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {failedRows.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <img src={emptyImg} alt="No Failed" width="150" />
                <Typography variant="body2" color="textSecondary">
                  No failed assignments.
                </Typography>
              </div>
            ) : (
              <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Anchor ID</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Email</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Reason</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Row Number</th>
                  </tr>
                </thead>
                <tbody>
                  {failedRows.map((row, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{row.anchor_id}</td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{row.email}</td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{row.reason}</td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>{row.row_number}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* {value === 1 && failedRows.length > 0 && (
          <Button onClick={downloadFailedRows} startIcon={<FileDownloadIcon />} color="primary">
            Download Failed Rows
          </Button>
        )} */}
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BulkAssignExecutivesStatus.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  successRows: PropTypes.array,
  failedRows: PropTypes.array,
  downloadFailedRows: PropTypes.func,
};

export default BulkAssignExecutivesStatus;
