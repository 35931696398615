import {
  Badge,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { fetchAgentTrackData } from "../../api";
import { toast } from "react-toastify";
import { f } from "../../utility/Formater";
import CustomTableV2 from "../../Components/CustomTable/CustomTableV2";
import AgentVisitDialog from "../../Components/Dialogs/AgentVisitsModal";
import MapIcon from "@mui/icons-material/Map";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from "react-router";
import SetTargetModal from "../../Components/Dialogs/SetTarget";
import AddIcon from "@mui/icons-material/Add";
import MapView from "../../Components/Dialogs/MapView";
import ViewTargetModal from "../../Components/Dialogs/ViewTarget";
import { GlobalContext } from "../../Context/GlobalContext";
import ViewAgentFilesStatus from "../../Components/Dialogs/ViewAgentFilesStatus";
import AgentTrackerExports from "../../Components/Dialogs/AgentTrackerExports";
import { UserContext } from "../../Context/UserContext";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


function AgentTrackerTable() {
  const { agentsCurrentMonthTargetPercentageState } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [
    agentsCurrentMonthTargetPercentage,
    setAgentsCurrentMonthTargetPercentage,
  ] = agentsCurrentMonthTargetPercentageState;
  const [tableData, setTableData] = useState([]);
  const [totalCount, setCount] = useState(0);
  const [filter, setFilter] = useState(
    localStorage.getItem("agentTrackerFilter") || "live_till_date"
  );
  const navigate = useNavigate();
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);

  const [visitData, setVisitData] = useState({});
  const [cashToBeCollected, setCashToBeCollected] = useState(0);
  const [agentTarget, setAgentTarget] = useState({
    open: false,
    agents: [],
  });
  const [openMapView, setOpenMapView] = useState(false);
  const [viewTarget, setViewTarget] = useState({
    open: false,
    agentCode: "",
  });
  const [agentFilesStatusPopup, setAgentFilesStatusPopup] = useState({
    open: false,
    agentDetails: null
  });
  const [exportDialog, setExportDialog] = useState(false);
  const [city, setCity] = useState("");
  const [editCity, setEditCity] = useState(false);
  const [name, setName] = useState("");
  const [editName, setEditName] = useState(false);

  function handlePageChange(page) {
    setPage(page);
    fetchTableData(
      page,
      limit,
      "filter",
      localStorage.getItem("agentTrackerFilter") || "live_till_date",
      null,
      true
    );
  }

  let columns = [
    {
      label: "View Targets",
      render: (i) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              setViewTarget({
                ...viewTarget,
                open: true,
                agentCode: tableData?.[i]?.agent_code,
                name: tableData?.[i]?.name,
                department: tableData?.[i]?.department,
              });
            }}
            // size="medium"
            sx={{
              background: "linear-gradient(90deg, #801AE6 0%, #A21AE6 100%)",
              borderRadius: 27,
              width: "100px",
            }}
            disableElevation
            startIcon={<MyLocationOutlinedIcon fontSize="small" />}
          >
            {agentsCurrentMonthTargetPercentage?.[tableData?.[i]?.agent_code]
              ? `${
                  agentsCurrentMonthTargetPercentage?.[
                    tableData?.[i]?.agent_code
                  ]
                }%`
              : "View"}
          </Button>
        );
      },
    },
    {
      label: (<div sx={{width: 200}}>
        {editCity ? (
          <TextField
            autoFocus
            variant="outlined"
            size="small"
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
               fetchSearchTableData();
              }
            }}
            sx={{width: 200}}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={() => {
                  if(city == "") {
                    setEditCity(false)
                  } else {
                    setCity("")
                    setEditCity(false);
                    fetchTableData(page, limit, "filter", filter, null, false, "", name);
                  }
                }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        ) : (
          <span onClick={() => setEditCity(true)}>
            City <IconButton size="small"><SearchIcon fontSize="small" /></IconButton>
          </span>
        )}
      </div>),
      render: (i) => {
        return f(tableData[i]?.city);
      },
      width: 500,
    },
     {
      label: (<div sx={{width: 200}}>
        {editName ? (
          <TextField
            autoFocus
            variant="outlined"
            size="small"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchSearchTableData();
              
              }
            }}
            sx={{width: 200}}
            InputProps={{
              endAdornment: (
                <IconButton size="small"   onClick={() => {
                  if(name == "") {
                    setEditName(false)
                  } else {
                    setName("")
                    setEditName(false);
                    fetchTableData(page, limit, "filter", filter, null, false, city, "");
                  }
                }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        ) : (
          <span onClick={() => {setEditName(true);
            // setName("");
          }}
          >
            Name <IconButton size="small"><SearchIcon fontSize="small" /></IconButton>
          </span>
        )}
      </div>),
        render: (i) => {
          const isDisabled = !Boolean(tableData[i]?.visitsData);
          return (
            <span className="d-flex flex-row">
              <IconButton
                size="small"
                color="secondary"
                className="me-2"
                disabled={isDisabled}
                onClick={() => {
                  setVisitData({
                    open: true,
                    agentData: tableData[i] || {},
                  });
                }}
              >
                <Badge
                  badgeContent={
                    isDisabled ? 0 : tableData[i]?.visitsData?.visit_count || 0
                  }
                  color="info"
                >
                  <MapIcon fontSize="small" />
                </Badge>
              </IconButton>
              {f(tableData[i]?.name)}
            </span>
          );
        },
      width: 400,
    },
    filter !== "today" && {
      label: (
        <span className="w-100 d-flex flex-column lh-1">
          <span className="text-nowrap">Distance Traveled</span>
          <br />
          <span className="w-100 text-center" style={{ fontSize: 12 }}>
            (In km)
          </span>
        </span>
      ),
      render: (i) => {
        const distance = (
          (tableData[i]?.total_traveled_distance || 0) / 1000
        ).toFixed(2);
        return f(Number(distance)?.toLocaleString("en-IN"));
      },
    },
    {
      label: "Mobile No.",
      render: (i) => {
        return f(tableData[i]?.mobile_number);
      },
    },
    {
      label: "Dealers",
      render: (i) => {
        return (
          <div
            className="pointer"
            style={{
              padding: "5px 10px",
            }}
            onClick={() => {
              navigate(`/update-agent/${tableData[i]?.agent_code}`, {
                state: {
                  path: "/agent-tracker",
                  scroll: "dealer",
                },
              });
            }}
          >
            {(tableData[i]?.anchorCount || 0) + " Dealers"}
          </div>
        );
      },
      value: "anchorCount",
      width: 130,
    },
    {
      label: <span className="text-nowrap">Files-Logged In</span>,
      render: (i) => {
        return filter === "today" ? (
          tableData[i]?.logged_in_file || 0
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setAgentFilesStatusPopup({
                open: true,
                agentDetails: tableData[i]
              });
            }}
          >
            <div>
              {tableData[i]?.logged_in_file || 0} /{" "}
              {tableData[i]?.agentTargetData?.total_login || 0}
            </div>
          </Button>
        );
      },
      value: "amount",
      width: 130,
    },
    {
      label: "Disbursed",
      render: (i) => {
        return filter === "today" ? (
          tableData[i]?.disbursed_files || 0
        ) : (
          <div>
            {tableData[i]?.disbursed_files || 0} /{" "}
            {tableData[i]?.agentTargetData?.total_disbursement || 0}
          </div>
        );
      },
      value: "disbursed_files",
      width: 130,
    },
    {
      label: "Departments",
      render: (i) => {
        if (
          tableData[i]?.department &&
          Array?.isArray(tableData[i]?.department)
        ) {
          return tableData[i]?.department?.map((item) => {
            return <span className="agent-dep-chip">{item}</span>;
          });
        } else {
          return f(null);
        }
      },
      value: "department",
      width: 130,
    },
    {
      label: <span className="text-nowrap">Cash receipt Amt</span>,
      render: (i) => {
        let cash = tableData[i]?.cash_receipt_amount;
        return (
          <span
            className={cash ? "pointer" : ""}
            onClick={() => {
              navigate(`/cash-receipt-data`, {
                state: {
                  filter: "agent_name",
                  data: tableData[i],
                  date: filter,
                },
              });
            }}
          >
            {f(cash, "cur")}
          </span>
        );
      },
      value: "cash_receipt_amount",
      width: 130,
    },
    {
      label: <span className="text-nowrap">Money collected</span>,
      render: (i) => {
        return f(tableData[i]?.money_collected, "cur");
      },
      value: "money_collected",
      width: 120,
    },
    {
      label: <span className="text-nowrap">GPS Installed</span>,
      render: (i) => {
        return tableData[i]?.gps_count || 0;
      },
      value: "gps_count",
      width: 120,
    },
  ].filter(Boolean);


  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    search,
    pageChange,
    city,
    name
  ) => {
    let filter_data = typeValue || localStorage.getItem("agentTrackerFilter");
    if (pageChange) setPageLoading(true);
    else setLoading(true);

    try {
      let params = `/${filter_data}?page=${page}&limit=${limit}`;
      if (typeof city === "string" && city.trim()) params += `&city=${encodeURIComponent(city)}`;
      if (typeof name === "string" && name.trim()) params += `&name=${encodeURIComponent(name)}`;
      let { data } = await fetchAgentTrackData(params);
      if(user?.isUserPresenter){
        let city = ""
        data["data"] = data?.data?.filter(itm=>{
          if(city === itm?.city){
            return false;
          }
          city = itm?.city;
          return true;
        }) || []
      }
      setTableData(data?.data || []);
      setCount(data?.total_count);
      const moneyToBeClctd = data?.data?.reduce((acc, rowData) => {
        const cash_receipt_amount = rowData?.cash_receipt_amount || 0;
        const money_collected = rowData?.money_collected || 0;
        return acc + (cash_receipt_amount - money_collected);
      }, 0);
      setCashToBeCollected(moneyToBeClctd);
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
    if (pageChange) setPageLoading(false);
    else setLoading(false);
  };

  const fetchSearchTableData = () => {
    fetchTableData(page, limit, "filter", filter, null, false, city, name);
  }

  const IntialDataFetch = () => {
    let initialFilter =
      localStorage.getItem("agentTrackerFilter") || "live_till_date";
    fetchTableData(page ?? 1, 50, "filter", initialFilter);
  };

  const handleChange = (event) => {
    localStorage.setItem("agentTrackerFilter", event.target.value);
    setFilter(event.target.value);
    setPage(1);
    fetchTableData(1, 50, "filter", event.target.value, null, false, city, name);
  };

  useEffect(() => {
    IntialDataFetch();
  }, []);

  return (
    <>
      <div className="px-3">
        <div className="d-flex flex-row justify-content-between py-3">
          <div className="d-flex flex-row align-items-center">
            <div
              style={{
                fontSize: 18,
                fontWeight: 600,
                marginRight: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              Agent Tracker
            </div>
            <div
              style={{
                width: 200,
              }}
            >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter}
                  label="Date"
                  onChange={handleChange}
                >
                  <MenuItem value={"today"}>Today</MenuItem>
                  <MenuItem value={"current_month"}>Current Month</MenuItem>
                  <MenuItem value={"last_seven_days"}>Last 7 days</MenuItem>
                  <MenuItem value={"last_month"}>Last Month</MenuItem>
                  <MenuItem value={"live_till_date"}>Live Till Date</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="ms-3">
              <Button
                variant="contained"
                onClick={() => {
                  setOpenMapView(true);
                }}
                size="medium"
                sx={{ bgcolor: "#4D19D2" }}
                disableElevation
                startIcon={<PinDropOutlinedIcon fontSize="small" />}
              >
                View Map
              </Button>
            </div>

            <Button
              className="ms-2"
              variant="contained"
              disableElevation
              size="medium"
              sx={{ bgcolor: "#4D19D2" }}
              onClick={() => {
                setAgentTarget({
                  open: true,
                });
              }}
            >
              <AddIcon className="me-1" /> SET TARGET
            </Button>
          </div>
          <div className="d-flex flex-row align-items-center">
            {!user?.isUserPresenter && <Button
              className="zoom-animation ms-2 me-4"
              variant="outlined"
              sx={{
                fontSize: 10,
                textTransform: "capitalize",
                my: 2,
                cursor: "default",
              }}
              size="small"
              color="secondary"
              onClick={() => {
                navigate(`/cash-receipt-data`, {
                  state: { filter: "agents", date: filter },
                });
              }}
              // disabled={cashRctLoading}
            >
              Money Held By Agents :{" "}
              {cashToBeCollected ? f(cashToBeCollected, "cur") : 0}
            </Button>}
            <Button className="ms-2" color="inherit" onClick={()=>{ setExportDialog(true) }} >
              <FileDownloadIcon/> Export
            </Button>
          </div>
        </div>
        <CustomTableV2
          tableData={tableData}
          tableHeight="80vh"
          columns={columns}
          defaultLimit={50}
          totalCount={totalCount}
          isLoading={isLoading}
          // serialNo={true}
          // stickSL={true}
          page={page}
          limit={limit}
          isPaginated={true}
          allowAll={true}
          pageLoading={isPageLoading}
          onPageChange={handlePageChange}
        />
      </div>

      {/* <CustomCalendar/> */}
      {visitData?.open && (
        <AgentVisitDialog
          open={visitData?.open}
          count={visitData?.visit_count}
          name={visitData?.name}
          visitArray={visitData?.daily_data || []}
          agentData={visitData?.agentData}
          handleClose={() => {
            setVisitData({
              open: false,
            });
          }}
        />
      )}

      {agentTarget?.open && (
        <SetTargetModal
          open={agentTarget?.open}
          handleClose={(reload) => {
            setAgentTarget({ open: false });
            if (reload) IntialDataFetch();
          }}
        />
      )}
      {openMapView && (
        <MapView
          open={openMapView}
          handleClose={() => {
            setOpenMapView(false);
          }}
        />
      )}
      {viewTarget?.open && (
        <ViewTargetModal
          viewTarget={viewTarget}
          open={viewTarget?.open}
          handleClose={() => {
            setViewTarget({ ...viewTarget, open: false });
          }}
        />
      )}
      {agentFilesStatusPopup?.open && (
        <ViewAgentFilesStatus
          open={agentFilesStatusPopup.open}
          handleClose={() =>
            setAgentFilesStatusPopup((prev) => ({ ...prev, open: false }))
          }
          agentDetails={agentFilesStatusPopup.agentDetails}
        />
      )}
      <AgentTrackerExports open={exportDialog} handleClose={()=>{setExportDialog(false)}} />
    </>
  );
}

export default AgentTrackerTable;
